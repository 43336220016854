import * as React from "react";
import type { SVGProps } from "react";
const SvgCommand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}
  >
    <path
      stroke="#71717A"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.5 2v6A1.5 1.5 0 1 0 8 6.5H2A1.5 1.5 0 1 0 3.5 8V2A1.5 1.5 0 1 0 2 3.5h6A1.5 1.5 0 1 0 6.5 2"
    />
  </svg>
);
export default SvgCommand;
