import { useNavigate } from "@remix-run/react";
import {
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
} from "kbar";
import { HomeIcon } from "lucide-react";
import React, { forwardRef, PropsWithChildren } from "react";
import { Fragment } from "react/jsx-runtime";
import { Command, Search } from "./icons";

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box" as React.CSSProperties["boxSizing"],
  outline: "none",
  border: "none",
  background: "hsl(var(--background))",
  color: "hsl(var(--foreground))",
};

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  background: "hsl(var(--background))",
  color: "hsl(var(--foreground))",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "hsl(var(--shadow))",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

const ResultItem = forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: string | null | undefined;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        style={{
          padding: "12px 16px",
          background: active ? "var(--a1)" : "transparent",
          borderLeft: `2px solid ${
            active ? "var(--foreground)" : "transparent"
          }`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}
          >
            {action.shortcut.map((sc) => (
              <kbd
                key={sc}
                style={{
                  padding: "4px 6px",
                  background: "rgba(0 0 0 / .1)",
                  borderRadius: "4px",
                  fontSize: 14,
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);

ResultItem.displayName = "Result";

interface KBarProps {}

export function KBar(props: PropsWithChildren<KBarProps>) {
  const navigate = useNavigate();

  const initialActions = [
    {
      id: "homeAction",
      name: "Home",
      shortcut: ["h"],
      keywords: "back",
      section: "Navigation",
      perform: () => navigate("/"),
      icon: <HomeIcon />,
      subtitle: "Subtitles can help add more context.",
    },
    {
      id: "conversationsAction",
      name: "Conversations",
      shortcut: ["c"],
      keywords: "help",
      section: "Navigation",
      perform: () => navigate("/conversations"),
    },
  ];

  function RenderResults() {
    const { results, rootActionId } = useMatches();

    return (
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === "string" ? (
            <div style={groupNameStyle}>{item}</div>
          ) : (
            <ResultItem
              action={item}
              active={active}
              currentRootActionId={rootActionId}
            />
          )
        }
      />
    );
  }

  return (
    <KBarProvider actions={initialActions}>
      <KBarPortal>
        <KBarPositioner className="bg-modal">
          <KBarAnimator style={animatorStyle}>
            <KBarSearch style={searchStyle} />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {props.children}
    </KBarProvider>
  );
}

type SearchProps = {
  className?: string;
};

export function KBarSearchInput(props: SearchProps) {
  const { className } = props;
  const { query } = useKBar();

  const openKBar = () => {
    query.toggle();
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      query.toggle();
    }
  };
  return (
    <div
      className={`relative ${className}`}
      role="button"
      tabIndex={0}
      onClick={() => openKBar()}
      onKeyDown={(evt) => handleKeydown(evt)}
    >
      <Search className="box-border absolute p-2 w-10 h-8 inset-y-1/2 -translate-y-4" />
      <div className="h-10 w-36 box-border pl-8 bg-subtle w-full rounded-md border border-input p-2 text-sm text-muted2">
        Search
      </div>
      <div className="box-border absolute right-2 px-1 pt-1 inset-y-1/2 -translate-y-4">
        <div className="flex flex-row border px-1 items-center justify-center">
          <div className="pr-px">
            <Command />
          </div>
          <span className="leading-relaxed text-xs text-muted2 pl-px">K</span>
        </div>
      </div>
    </div>
  );
}
